import React, { useEffect, useState } from "react";
import { t } from "./Translations/TranslationUtils";
import { CreateBpLogo } from "../static/icons/CreateBpLogo";
import { APICALL } from "../services/ApiServices";
import { fetchAllBrandPartnerUrl } from "../routes/ApiEndPoints";
import { useNavigate } from "react-router-dom";
import LoadingIcon from "../utils/LoadingIcon";
import Cards from "../components/molecules/Cards";
import styles from "../pages/Shipment/shimpment.module.css";
import { useUserContext } from "../routes/Contextlib";
import AccessControl from "../services/AccessControl";
import CommonShipmentServices from "../services/CommonShipmentServices";
import ActionButtonGroup from "../components/molecules/ActionButtonGroup";
import WindowHeightComponent from "../commonComponents/WindowHeightComponent";
import CommonServices from "../services/CommonServices";
import Popup from "../utils/popup";

export default function ShipmentBrandPartnerDashboard() {
  localStorage.removeItem("backbuttonURL");
  const { user } = useUserContext();
  const ignoreuserroles = ["admin", "superviosr"];
  const [bpDetails, setBpDetails] = useState<any[]>([]);
  const navigate = useNavigate();
  const [showPage, setShowPage] = useState(false);
  const [showTrackTimePopup, setShowTrackTimePopup] = useState(null);

  const handleTrackTimePopup = async (id: any) => {
    console.log(user?.trackTime);
    if (user?.trackTime.includes("no")) {
      handleBpselect(id, "no")
    } else if (user?.trackTime.includes("maybe")) {
      setShowTrackTimePopup(id)
    } else {
      handleBpselect(id, "yes")
    }
  }

  const handleBpselect = async (id: any, trackTimeResponse: string = "") => {
    localStorage.setItem("trackTime", trackTimeResponse)
    localStorage.setItem("bp_id", id);
    const status = await CommonShipmentServices.setBpDetails(id)
    if (status) {
      navigate(`/shipment-dashboard`);
    }
  };

  useEffect(() => {
    if (user) {
      getBrandPartners();
    }
  }, [user != null]);

  async function getBrandPartners() {
    try {
      const postData = {
        method: "POST",
        data: {
          user_id: (user?.roles?.map((role) => role.toLowerCase()) || []).some(
            (role) => ignoreuserroles?.includes(role)
          )
            ? null
            : user?.user_id,
        },
      };
      const response = await APICALL.service(
        fetchAllBrandPartnerUrl,
        "POST",
        postData,
        true
      );

      if (response.status === 200) {
        let data: any = response.data.filter((obj: any) => (obj.issaved === true && obj.status === true));
        setBpDetails(data);
        if (response?.data?.length === 1) {
          handleTrackTimePopup(response?.data[0]?.brand_partner_id);
          // const bp_id = response?.data[0]?.brand_partner_id;
          // await handleBpselect(bp_id);
          // navigate('/shipment-dashboard');
        }
        setShowPage(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      {!showPage ? (
        <LoadingIcon display="none" />
      ) : (
        <div className="row">
          <div className="col-md-11 ms-auto px-4">
            <WindowHeightComponent>
              <div className="d-flex overflow-auto flex-column h-100">
                <div className="page-title">{t("Brand partner")}</div>
                <div className={`${styles.brand_partner_dashboard_body} flex-1 overflow-auto`}>
                  <div className="d-flex flex-wrap mt-2 px-2 ">
                    {bpDetails && Object.keys(bpDetails).length > 0
                      ? Object.values(bpDetails).map((item: any) => {
                        return (
                          <div
                            className={`${styles.shipmentcardouter} `}
                            key={item.brand_partner_id}
                            onClick={() => handleTrackTimePopup(item.brand_partner_id)}
                          >
                            <Cards img={item.brand_logo} altText={item.title} />
                          </div>
                        );
                      })
                      : t("No access to any brand partners")}
                  </div>
                </div>
                <ActionButtonGroup
                  backTitle={t("Back")}
                  handleBackClick={() => navigate('/')}
                />
              </div>
              {showTrackTimePopup != null && (
                <Popup
                  body={t('Enable time tracking?')}
                  modalSize="lg"
                  close={() => setShowTrackTimePopup(null)}
                  submit={() => handleBpselect(showTrackTimePopup, "yes")}
                  cancel={() => handleBpselect(showTrackTimePopup, "no")}
                  yestext={t('Yes')}
                  notext={t('No')}
                />
              )
              }
            </WindowHeightComponent>
          </div>
        </div>
      )}
    </AccessControl>
  );
}
