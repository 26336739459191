import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImageView } from '../../molecules/QualityControlMolecules/ImageView';
import Layout from '../../../pages/Shipment/Layout';
import { t } from '../../../pages/Translations/TranslationUtils';
import { APICALL } from '../../../services/ApiServices';
import {
    createQaIissue,
    getQA,
    fetchEach,
    fetchList,
    createQa,
    createDataEntry
} from '../../../routes/ApiEndPoints';
import AddIssueInfo from '../../molecules/QualityAssurance/AddIssueInfo';
import IssueList from '../../molecules/QualityAssurance/IssueList';
import IssueDetails from '../../molecules/QualityAssurance/IssueDetails';
import { ProductInfo } from '../../molecules/QualityControlMolecules/ProductInfo';
import { ConfigConstants, QaConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import QaFinishMolecule from '../../molecules/QualityAssurance/QaFinishMolecule';
import CommonShipmentServices from '../../../services/CommonShipmentServices';
import styles from "../../organisms/organisms.module.css";
import QaIssueOverview from '../../molecules/QualityAssurance/QaIssueOverview';
import { WorkflowStageNames } from '../../../utils/constants/WorkflowStageNames';
import CommonServices from '../../../services/CommonServices';
import ScreenDisable from '../../../utils/ScreenDisable';
import Button from '../../atoms/Button';
import WorkflowItemInfo from '../../molecules/WorkflowItemInfo';

const QualityAssuranceOrganism: React.FC = () => {
    const navigate = useNavigate();
    const localdata = CommonShipmentServices.getPostData();
    const bpdetails: any = JSON.parse(localStorage?.getItem('bp_details') ?? '{}');
    const [dataentry, setDataEntry] = useState<any>({})
    const [qadata, setQadata] = useState<any>({});
    const [serialnumber, setSerialNumber] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const [totaltime, setTotalTime] = useState(0);
    const [selectedTier, setSelectedTier] = useState("");
    const [config, setConfigData] = useState<any>({
        addissuedata: {
            issue_size: [],
            // issue_location: [], please do not remove this code
            repair_assessment: [],
            work_area: [],
        },
        configdata: null
    });

    const [state, setState] = useState<any>({
        configdata: null,
        activetab: 0,
        issue_size: null,
        // issue_location: null, please do not remove this code
        repair_assessment: null,
        work_area: null,
        frontimage: '',
        backimage: '',
        issues: null,
        issue: null,
        issuecount: '01',
        type: QaConstants.FORM_KEYS[0],
        issueinfo: null,
        viewfinish: false,
        viewAddIssueInfo: false,
        viewoverview: false,
        timerrunning: true,
        ifrepairskiped: true,
        createDummyIssue: false
    });
    const [commentData, setCommentData] = useState({
        type: "renewal_record",
        type_id: null,
        stagename: "quality_assurance",
        commentdata: {}
    });
    useEffect(() => {
        fetchData();
        fetchQaData();
    }, []);

    useEffect(() => {
        if (localdata?.user_id != null && commentData?.type_id != null && localStorage.getItem("trackTime") === "yes") {
            timeTracker(true);

            window.addEventListener('beforeunload', () => timeTracker());
            return () => {
                timeTracker();
                window.removeEventListener('beforeunload', () => timeTracker());
            }
        }
    }, [commentData?.type_id != null, localdata?.user_id != null]);

    const timeTracker = async (reloaded: boolean = false) => {
        let time = await CommonShipmentServices.saveTimerApi(
            'renewal',
            commentData?.type_id,
            localdata?.user_id,
            WorkflowStageNames.QUALITYASSURANCE,
            reloaded
        );
    }

    const fetchQaData = async () => {
        try {
            let postdata = {
                ...localdata,
                quality_assurance_id: id
            };
            const response = await APICALL.service(getQA, 'POST', postdata);
            let issues: any = [];

            let qsstatustruecount = 0;
            let qsstatusnullcount = 0;
            let qsstatusfalse = false;
            let count = 0;
            let createDummyIssue = false;

            if ((response?.data?.quality_assurance_issues).length === 0 && !response?.data?.quality_assurance_issues[0]?.quality_control_issue_id) {
                createDummyIssue = true;
            }

            response?.data?.quality_assurance_issues?.forEach((issue: any) => {
                count++;
                if (issue.quality_status === null) {
                    qsstatusnullcount++;
                } else if (issue.quality_status === true) {
                    qsstatustruecount++
                } else if (issue.quality_status === false) {
                    qsstatusfalse = true
                }
                const combined = {
                    ...issue.quality_control_issues,
                    quality_assurance_issue_id: issue.quality_assurance_issue_id,
                    quality_control_issue_id: issue.quality_control_issue_id,
                    quality_status: issue.quality_status,
                    issue_repaired: issue.issue_repaired
                };
                issues.push(combined);
            });

            const firstNullQualityStatusIssue = issues?.find((issue: any) => issue.quality_status === null);
            const firstNullQualityStatusIssueIndex = issues?.findIndex((issue: any) => issue?.quality_assurance_issue_id === firstNullQualityStatusIssue?.quality_assurance_issue_id);
            let viewfinish = false;
            let viewoverview = false;
            let viewAddIssueInfo = false;
            let ifrepairskiped = true;
            // if (count == qsstatustruecount || response?.data?.renewal_record?.is_repairable == false) {
            //     ifrepairskiped = false;
            if (count == qsstatustruecount
            ) {
                //  || response?.data?.renewal_record?.is_repairable == false) {
                ifrepairskiped = false;
                viewfinish = true;
            }
            else if (qsstatusnullcount == 0 && qsstatusfalse) {
                viewoverview = true
            } else {
                viewAddIssueInfo = true
            }

            setQadata(response?.data);
            if (response?.data?.renewal_record) {
                setCommentData({ ...commentData, type_id: response?.data?.renewal_record?.renewal_id, commentdata: response?.commentdata })

                const datarequest = {
                    "method": "POST",
                    "data": {
                        data: response?.data?.renewal_record
                    }
                }
                const dataentry = await APICALL.service(fetchEach, 'POST', datarequest, true);

                if (dataentry?.status == 200) {
                    setDataEntry(dataentry?.data);
                    let productbackimage = (response?.data?.issueimage[0]?.back_image ? response?.data?.issueimage[0]?.back_image : null);
                    let productfrontimage = (response?.data?.issueimage[0]?.front_image ? response?.data?.issueimage[0]?.front_image : null);
                    if (productbackimage == null) {
                        productbackimage = dataentry?.data?.renewal_product_info?.back_image
                    }
                    if (productfrontimage == null) {
                        productfrontimage = dataentry?.data?.renewal_product_info?.front_image
                    }
                    console.log(dataentry?.data?.tier);

                    setSelectedTier(dataentry?.data?.tier ?? "")
                    // Iterate through the properties of the combined object            
                    setState({
                        ...state,
                        frontimage: productfrontimage ?? '',
                        backimage: productbackimage ?? '',
                        issues: issues,
                        issue: firstNullQualityStatusIssue,
                        viewfinish: viewfinish,
                        ifrepairskiped: ifrepairskiped,
                        viewoverview: viewoverview,
                        viewAddIssueInfo: viewAddIssueInfo,
                        createDummyIssue: createDummyIssue,
                        issueinfo: response?.data?.issueinfo,
                        issuecount: CommonServices.convertNumberIntoTwoDigits(firstNullQualityStatusIssueIndex + 1)
                    })
                }
            }
            setSerialNumber(response?.data?.serial_number);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchData = async () => {
        try {
            let postdata = {
                "method": "POST",
                "data": {
                    "list": [
                        ConfigConstants.ISSUESIZE,
                        // ConfigConstants.ISSUELOCATION,
                        ConfigConstants.REPAIRASSESSMENT,
                        ConfigConstants.ZONE,
                        ConfigConstants.ENVIRONMENT,
                        ConfigConstants.ISSUETYPE
                    ],
                    "bpwiselist": [
                        ConfigConstants.BPWISE_WORK_AREA,
                    ],
                    "bp_id": [localdata?.brand_partner_id]
                }
            }
            const response = await APICALL.service(fetchList, 'POST', postdata, true);

            if (response?.status === 200) {
                setConfigData({
                    ...config,
                    addissuedata: {
                        issue_size: CommonServices.sortAlphabattically(response?.data?.IssueSize ?? []),
                        // please do not remove the below line, it may be required in future
                        // issue_location: CommonServices.sortAlphabattically(response?.data?.IssueLocation ?? []),
                        repair_assessment: CommonServices.sortAlphabattically(response?.data?.RepairAssessment ?? []),
                        work_area: CommonServices.sortAlphabattically(response?.data?.cd_work_area ?? []),
                    },
                    configdata: response?.data
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (type: string, selected: any) => {
        setState({ ...state, [type]: selected });
    }

    const handleStep = async (step: number, action: string, quality_status = true, issue_repaired = true) => {
        if (step == -1) {
            try {
                if (action === 'back') {
                    navigate(`/quality-assurance/manage`);
                } else {
                    let postdata = {
                        quality_assurance_id: id,
                        quality_control_issue_id: state?.issue?.quality_control_issue_id,
                        quality_assurance_issue_id: state?.issue?.quality_assurance_issue_id,
                        quality_status: quality_status,
                        issue_repaired: issue_repaired,
                        issue_size: state?.issue_size?.issue_size_id,
                        createDummyIssue: state?.createDummyIssue,
                        // please do not the below line
                        // issue_location: state?.issue_location?.issue_location_id,
                        repair_assessment: state?.repair_assessment?.repair_assessment_id,
                        work_area: state?.work_area?.work_area_id
                    }
                    const response = await APICALL.service(createQaIissue, 'POST', postdata);

                    if (response?.status === 200) {
                        let index = state?.issues.findIndex(
                            (item: any) => item?.quality_assurance_issue_id === state?.issue?.quality_assurance_issue_id
                        );
                        let updatedIssue = [];
                        let issueslist = JSON.parse(JSON.stringify(state?.issues))
                        if (index !== -1) {
                            // Create the updated object
                            updatedIssue = {
                                ...issueslist[index],
                                quality_status: quality_status,
                                issue_repaired: issue_repaired
                            };
                            issueslist[index] = updatedIssue;
                        }
                        let qsstatustruecount = 0;
                        let qsstatusnullcount = 0
                        let qsstatusfalse = false;
                        let count = 0

                        issueslist.forEach((issue: any) => {
                            count++;
                            if (issue.quality_status == null) {
                                qsstatusnullcount++;
                            } else if (issue.quality_status == true) {
                                qsstatustruecount++;
                            } else if (issue.quality_status == false) {
                                qsstatusfalse = true
                            }
                        });

                        let viewfinish = false;
                        let viewoverview = false;
                        if (count == qsstatustruecount) {
                            viewfinish = true;
                        } else if (qsstatusnullcount == 0 && qsstatusfalse) {
                            viewoverview = true
                        }

                        let nextIssue: any = [];
                        let nextIssueCount;

                        if (index >= (state?.issues.length - 1)) {
                            nextIssue = state?.issues?.find((issue: any) => issue.quality_status === null);
                        } else {
                            nextIssue = state?.issues[index + 1];
                        }
                        nextIssueCount = state?.issues?.findIndex((issue: any) => issue.quality_assurance_issue_id === nextIssue.quality_assurance_issue_id) + 1;

                        setState({
                            ...state,
                            issues: issueslist,
                            issue: nextIssue,
                            issuecount: CommonServices.convertNumberIntoTwoDigits(nextIssueCount),
                            issue_size: null,
                            // issue_location: null,
                            repair_assessment: null,
                            work_area: null,
                            type: QaConstants?.FORM_KEYS[0],
                            issueinfo: response?.quality_assurance_issue,
                            viewoverview: viewoverview,
                            viewfinish: viewfinish
                        })
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            let typeindex;
            if (action == 'back') {
                typeindex = QaConstants?.FORM_KEYS.findIndex((key: string) => key === state?.type) - 1;
            } else {
                typeindex = QaConstants?.FORM_KEYS.findIndex((key: string) => key === state?.type) + 1;
            }

            if (typeindex >= QaConstants?.FORM_KEYS.length) {
                typeindex = 0;
            }
            setState({ ...state, activetab: step, type: QaConstants?.FORM_KEYS[typeindex] });
        }
    }

    const handleFinishClick = async (status: boolean, tier: string = '', tierTitle: string = '') => {
        try {
            if (state.timerrunning) {
                if (state.createDummyIssue && (tierTitle.toLowerCase() === 'tier 2' || tierTitle.toLowerCase() === 'tier 3')) {
                    updateOrCreateQAissue(tier);
                }
                else if (!state.createDummyIssue && state.issues.every((issue: any) => issue.issue_repaired === false)
                    && (tierTitle.toLowerCase() === 'tier 2' || tierTitle.toLowerCase() === 'tier 3')
                ) {
                    let last_item = (state.issues.pop())
                    updateOrCreateQAissue(tier, last_item);
                }
                else {
                    let postdata: any = {
                        ...localdata,
                        quality_assurance_id: id,
                        serial_number: serialnumber,
                        finished: status,
                        tier: tier
                    }
                    const response = await APICALL.service(createQa, 'POST', postdata);
                    if (!status) {
                        localStorage.setItem("qaalert", t("Item is moved back to finishing stage"));
                    }
                    navigate(`/quality-assurance/manage`);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateOrCreateQAissue = async (tier = "", item: any = null) => {
        let postdata = {
            quality_assurance_id: id,
            quality_control_issue_id: item?.quality_control_issue_id ?? null,
            quality_assurance_issue_id: item?.quality_assurance_issue_id ?? null,
            quality_status: null,
            issue_repaired: true,
            createDummyIssue: true,
            serialNumber: serialnumber,
            setTier: tier
        }
        const response = await APICALL.service(createQaIissue, 'POST', postdata);
        await fetchQaData();
    }

    const handleBack = () => {
        navigate(`/quality-assurance/manage`);
    }

    const handleHoldClick = async () => {
        try {
            const postdata = {
                ...localdata,
                renewal_id: dataentry.renewal_id,
                status: "hold",
                qa_failed: true,
                user_id: 1,
                stagename: WorkflowStageNames.QUALITYASSURANCE,
                tier: bpdetails.product_tier
            };

            const response = await APICALL.service(createDataEntry, "POST", postdata);
            if (response?.status === 200) {
                // customAlert("error", t("Error while adding item to tier"), 6000)
                navigate(`/quality-assurance/manage`);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const setTimerRunning = (timer: boolean) => {
        setState((prevState: any) => ({ ...prevState, timerrunning: timer }));
    }

    return (
        <Layout
            pagename={('Quality assurance')}
            logo={true}
            timer={true}
            comments={commentData}
            hold={true}
            ongoing={localStorage.getItem("trackTime") === "yes" ? true : false}
            totaltime={totaltime}
            stage={WorkflowStageNames.QUALITYASSURANCE}
            setTimerRunning={setTimerRunning}
        >

            <WorkflowItemInfo serialNumber={dataentry?.renewal_product_info?.serial_number}
                productType={dataentry?.renewal_product_info?.product_type_title}
                productDescription={qadata?.renewal_record?.renewal_info?.product_description}
                sku={qadata?.renewal_record?.renewal_info?.sku}
                color={dataentry?.renewal_product_info?.color_title || dataentry?.renewal_product_info?.colorlabel}
                size={dataentry?.renewal_product_info?.size_title || dataentry?.renewal_product_info?.sizelabel} />

            <div className="row m-0 h-100 position-relative">
                <ScreenDisable display='none' />
                {/* left block start */}
                <div className="col-6 h-100 d-flex flex-column">
                    <div className={`${styles.QA_first_block} mb-3`}>
                        <div className="py-2 pb-2 px-xxl-5 px-3 border rounded shadow h-100 overflow-auto d-flex flex-column">
                            {/* <h3 className='text-gray'>{serialnumber}</h3> */}
                            {/* image block start */}
                            {/* <div className={`${styles.qaimg}`}> */}
                            <div className="flex-1 overflow-auto">
                                <ImageView
                                    front_image={state?.frontimage}
                                    back_image={state?.backimage}
                                />
                            </div>

                            {/* image block ends */}
                            {/* <ProductInfo
                                dataentry={dataentry?.renewal_product_info}
                            /> */}
                            {/* Product info block end  */}
                            {/* </div> */}
                        </div>
                    </div>
                    <div className='h-30'>
                        <IssueList
                            issues={state?.issues}
                            selectedissue={state?.issue}
                            title={t('Issue')}
                            issuecount={state?.issuecount}
                            configdata={config?.configdata}
                            handleIssueForward={handleStep}
                            handleSelected={
                                (issue: any, count: string) => setState({
                                    ...state,
                                    issue: issue,
                                    issuecount: count,
                                    issue_size: null,
                                    // issue_location: null,
                                    repair_assessment: null,
                                    work_area: null
                                })
                            }
                            selectedTier={selectedTier}
                            disablebuttons={state.ifrepairskiped}
                            timer={state.timerrunning}
                        />
                    </div>
                </div>
                {/* left block ends */}
                <div className="col-6 h-100 d-flex flex-column">
                    {state.viewfinish &&
                        <div className='col-12 h-100 border rounded position-relative'>
                            <div className="d-flex h-100 justify-content-center h-90 align-items-center">
                                <QaFinishMolecule
                                    handleClick={handleFinishClick}
                                    selectedTier={selectedTier}
                                />
                            </div>
                            {handleBack && <Button className='back-btn position-absolute bottom-0 left-0 mb-3 ms-3 shadow-none' handleClick={handleBack} title={t("Back")} />}
                        </div>
                    }
                    {state.viewoverview &&
                        <QaIssueOverview
                            issues={state.issueinfo}
                            data={config.addissuedata}
                            handleClick={handleHoldClick}
                            handleBack={handleBack}
                        />
                    }
                    {!state.viewfinish && !state.viewoverview && state.viewAddIssueInfo &&
                        <>
                            <div className={`${styles.QA_first_block} mb-3`}>
                                <div className="py-4 px--xxl-5 px-4 border rounded shadow position-relative h-100 d-flex flew-wrap flex-column">
                                    {Object.keys(config.addissuedata).map((key, index) => (
                                        <React.Fragment key={key}>
                                            {(state.activetab != -1 && state.activetab === index) &&
                                                <AddIssueInfo
                                                    data={config.addissuedata[state.type]}
                                                    selecteddata={state[state?.type]}
                                                    type={state?.type}
                                                    index={QaConstants?.FORM_KEYS.findIndex((key: string) => key === state?.type)}
                                                    length={Object.keys(config?.addissuedata)?.length}
                                                    handleStep={handleStep}
                                                    handleClick={handleClick}
                                                    timer={state.timerrunning}
                                                />
                                            }
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            <div className={`h-30 border rounded shadow position-relative ${styles.qaissuesblock}`}>
                                <IssueDetails
                                    title={t('Issue') + ' ' + (state.issuecount ?? '01')}
                                    issue_size={state?.issue_size?.title ?? ''}
                                    // issue_location={state.issue_location?.title ?? ''}
                                    repair_assesment={state?.repair_assessment?.title ?? ''}
                                    work_area={state?.work_area?.title ?? ''}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default QualityAssuranceOrganism;
