import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../pages/Shipment/Layout'
import InputTextfield from '../../atoms/InputTextField'
import Button from '../../atoms/Button'
import IconData from '../../../static/IconData'
import styles from '../organisms.module.css';
import ProductImageCard from '../../atoms/ProductImageCard'
import { APICALL } from '../../../services/ApiServices'
import {
    addLaundryProducts,
    createBatch,
    fetchBatch,
    fetchConfigData,
    removeLaundryProducts
} from '../../../routes/ApiEndPoints'
import Close from '../../../static/icons/Close'
import { useNavigate } from 'react-router-dom'
import ErrorMessage from '../../atoms/ErrorMessage'
import FormValidation from '../../../services/FormValidation'
import customAlert from '../../atoms/CustomAlert'
import CommonShipmentServices from '../../../services/CommonShipmentServices'
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants'
import { t } from '../../../pages/Translations/TranslationUtils'
import { WorkflowStageNames } from '../../../utils/constants/WorkflowStageNames'
import ScreenDisable from '../../../utils/ScreenDisable'
import Popup from '../../../utils/popup'
import DeliveredPopupContent from '../../molecules/LaundryExternal/DeliveredPopupContent'

interface Input {
    title: string;
    serialnumber: string;
    products: any;
    producttypes: any;
    titleerror: string;
    batchstatus: string;
    totaltime: number;
    timerrunning: boolean;
    arrived: boolean;
    pickup_date: any;
}

const ExternalCreateBatchOrganism: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const localdata = CommonShipmentServices.getPostData(); // getting the required data from the local storage
    const id = Number(urlParams.get('id'));
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [input, setInput] = useState<Input>({ // state object
        title: '',
        serialnumber: '',
        products: {},
        producttypes: {},
        titleerror: '',
        batchstatus: '',
        totaltime: 0,
        timerrunning: true,
        arrived: false,
        pickup_date: null
    });

    useEffect(() => { // calling the batch info whenever the component loads
        if (localdata?.user_id != null) {
            fetchAPI();
            const storageMessage = localStorage.getItem("successAlertMessage");
            if (storageMessage) {
                localStorage.removeItem("successAlertMessage");
                customAlert("success", t(storageMessage), 2000);
            }

        }
        if (localStorage.getItem("trackTime") === "yes") {
            timeTracker(true);
            window.addEventListener('beforeunload', () => timeTracker());
            return () => {
                timeTracker();
                window.removeEventListener('beforeunload', () => timeTracker());
            }
        }
    }, [localdata?.user_id != null]);

    const timeTracker = async (reloaded: boolean = false) => {
        let time = await CommonShipmentServices.saveTimerApi(
            'bp',
            localdata?.brand_partner_id,
            localdata?.user_id,
            WorkflowStageNames.EXTERNALLAUNDRY,
            reloaded
        );
    }

    useEffect(() => { // To add/update item by using scanner        
        const handleKeyDown = async (event: KeyboardEvent) => {
            if (event.key === "Enter" && inputRef.current) {
                const scannedData = inputRef.current.value;
                if (scannedData) {
                    setInput((prevState) => ({
                        ...prevState,
                        serialnumber: scannedData
                    }));
                    handleAddProduct();
                }
            }
        };

        const handleMouseMove = () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("mousemove", handleMouseMove);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, [input]);

    const fetchAPI = async () => { // API call to fetch the batch info
        try {
            let postdata = {
                ...localdata,
                external_laundry_batch_id: id
            }
            const response = await APICALL.service(fetchBatch, 'POST', postdata);
            if (response?.status === 200) {
                let config = {
                    method: "POST",
                    data: {
                        type: "model",
                        name: "ProductType",
                    },
                };
                const configresponse: any = await APICALL.service(
                    fetchConfigData,
                    "POST",
                    config,
                    true
                );

                setInput((prevState) => ({
                    ...prevState,
                    title: response?.batch_title,
                    products: response?.data,
                    producttypes: configresponse.data,
                    batchstatus: response?.batch_status,
                    arrived: !response?.data.some((item: any) => item.laundry_status === true),
                    pickup_date: response?.pickup_date
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddProduct = async () => { // Adding products into batch
        if (input.timerrunning) {
            try {
                if (input.products?.length) {
                    // checking the incoming product is already present in the batch or not (returns boolean)
                    let nomatch = !input.products?.some((item: any) => item.serial_number === input.serialnumber);

                    if (nomatch && input.batchstatus !== 'IAL') { // adding products if the batch is not in arrival
                        saveProductsApi();
                    } else if (nomatch && input.batchstatus === 'IAL') { // giving error for new product when the batch is in arrival
                        customAlert(
                            "error",
                            t('Products cannot be added into arrival batch'),
                            2000
                        );
                    } else if (!nomatch && input.batchstatus === 'IAL') { // updating the status when the batch is in arrival
                        saveProductsApi();
                    } else {
                        customAlert(
                            "error",
                            t('Product is already added into this batch'),
                            2000
                        );
                    }
                } else { // adding products directly if there are no products in the batch
                    saveProductsApi();
                }
                if (inputRef.current) {
                    inputRef.current.value = "";
                    inputRef.current.focus();
                }
                setInput((prevState) => ({ ...prevState, serialnumber: '' }))

            } catch (error) {
                console.log(error);
            }
        }
    }

    const saveProductsApi = async () => { // Api call to add the products in batch
        try {
            let postdata = {
                ...localdata,
                external_laundry_batch_id: id,
                serial_number: input.serialnumber,
                laundry_status: input.batchstatus !== 'IAL'
            }
            const response = await APICALL.service(addLaundryProducts, 'POST', postdata);
            if (response?.status === 200) { // success response
                fetchAPI();
            } else if (response?.status === 204) { // success with no result
                fetchAPI();
                customAlert("error", t(response?.message), 2000);
            } else { // error response
                customAlert(
                    "error",
                    t(CommonConstants.SOMETHING_WENT_WRONG),
                    2000
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteProduct = async (serialnumber: string) => { // Api call to delete a product from the batch
        try {
            let postdata = {
                ...localdata,
                serial_number: serialnumber
            }
            const response = await APICALL.service(removeLaundryProducts, 'POST', postdata);
            if (response?.status === 200) {
                fetchAPI();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const validate = (value: string): boolean => {
        let error = FormValidation.nameValidation(value);
        setInput({ ...input, titleerror: error })

        return error == "";
    };

    const handleSave = async () => { // handling the save and creating batch
        if (input.timerrunning) {
            try {
                let isvalidated = validate(input.title);
                if (isvalidated) {
                    let postdata = {
                        ...localdata,
                        external_laundry_batch_id: id,
                        title: input.title,
                    };

                    const response = await APICALL.service(createBatch, 'POST', postdata);

                    if (response?.status === 200) {
                        navigate(`/laundry-external/batch-overview`);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const setTimerRunning = (timer: boolean) => {
        setInput((prevState: any) => ({ ...prevState, timerrunning: timer }));
    }

    return (
        <Layout
            pagename={('Laundry-External')}
            timer={true}
            logo={true}
            ongoing={localStorage.getItem("trackTime") === "yes" ? true : false}
            totaltime={input.totaltime}
            stage={WorkflowStageNames.EXTERNALLAUNDRY}
            setTimerRunning={setTimerRunning}
        >
            <div className="position-relative h-100 d-flex h-100 flex-column">
                <ScreenDisable display='none' />

                <div className="d-flex flex-wrap justify-content-between my-3">
                    <div className="">
                        <Button
                            title={t(CommonConstants.BACK)}
                            className='back-btn'
                            handleClick={() => navigate(`/laundry-external/batch-overview`)}
                        />
                    </div>
                    <div className='d-flex'>
                        <InputTextfield
                            className='form-control shadow-none'
                            value={input.serialnumber}
                            handleChange={(e: any) => setInput((prevState) => ({ ...prevState, serialnumber: e.target.value }))}
                            inputRef={inputRef}
                            autoFocus
                            readOnly={!input.timerrunning}
                        />
                        <Button
                            title={t(CommonConstants.ADD)}
                            className='submit-btn ms-3'
                            handleClick={handleAddProduct}
                        />
                    </div>
                    <div className='d-flex'>
                        <div className='position-relative'>
                            <InputTextfield
                                className='form-control shadow-none h-100'
                                value={input.title}
                                handleChange={(e) => setInput((prevState) => ({
                                    ...prevState,
                                    title: e.target.value,
                                    titleerror: ''
                                }))}
                                readOnly={!input.timerrunning}
                            />
                            <span className='position-absolute'>
                                <ErrorMessage errorMessage={t(input.titleerror)} />
                            </span>
                        </div>

                        <Button
                            title={t(CommonConstants.SAVE)}
                            className='submit-btn ms-3'
                            handleClick={handleSave}
                        />
                    </div>
                </div>
                {input.products.length > 0 ?
                    <div className="flex-1 overflow-auto">
                        <div className="row  m-0">
                            {Object.values(input.products).map((item: any) => (
                                <div className="col-3 col-xl-2 mb-4">
                                    <div className={`cursor-pointer h-100 position-relative rounded border text-center p-2 ${styles.product_width}`}>
                                        <ProductImageCard
                                            title={item.serial_number}
                                            imgurl={
                                                input.producttypes?.find(
                                                    (data: any) => data.product_type_id === Number(item.product_type)
                                                )?.front_image ?? ''
                                            }
                                        />
                                        {input.batchstatus !== 'IAL' &&
                                            <span className={`position-absolute top-0 end-0 cursor-pointer p-1 ${styles.laundry_product_close_btn}`}
                                                onClick={() => handleDeleteProduct(item.serial_number)}
                                            >
                                                <Close />
                                            </span>
                                        }
                                        {(input.batchstatus === 'IAL' && !item.laundry_status) &&
                                            <span className={`position-absolute top-0 end-0 cursor-pointer p-1`}>
                                                {IconData.success}
                                            </span>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> :
                    <div className={`row justify-content-center align-items-center m-0 ${styles.barcode_laundry}`}>
                        <div className="col-auto">
                            <div className="row">
                                {IconData.BarCodeScanIcon}
                            </div>
                            <div className='row justify-content-center'>
                                {t('Scan and add the items to the laundry')}
                            </div>
                        </div>
                    </div>
                }
            </div>
            {input.arrived && input.batchstatus == 'IAL' &&
                <Popup
                    title={t('Select delivery date')}
                    body={
                        <DeliveredPopupContent
                            id={id}
                            cancel={() => setInput({ ...input, arrived: false })}
                            pickup_date={input.pickup_date}
                        />
                    }
                    bodyclassName={styles.DeliveredPopupContent}
                    modalSize='xl'
                    cancel={() => setInput({ ...input, arrived: false })}
                />
            }
        </Layout>
    )
}

export default ExternalCreateBatchOrganism;
